import {Card, Collapse, List, Steps} from "antd-mobile";
import {Step} from "antd-mobile/es/components/steps/step";
import {MdFace} from "react-icons/md";
import style from "./info.module.css"

export default function StuInfo() {
    return <>
        <div style={{background: '#f6f6f6', height: '100vh', padding: '0.5em'}}>
            <div style={{borderRadius: '8px', overflow: 'hidden', marginBottom: '0.5em'}}>
                <List>
                    <List.Item prefix={<MdFace size={35} color={'#919191'}/>} description='计算机学院 2020201234'>
                        张三
                    </List.Item>
                </List>
            </div>

            <Card title={'体测预约状态'} style={{background: '#fff', marginBottom: '0.5em'}}>
                <Steps>
                    <Step title={'预约'} description={'选择体测的时间'}/>
                    <Step title={'体测'} description={'前往进行体测'}/>
                    <Step title={'查看成绩'} description={'等待生成成绩'}/>
                </Steps>
            </Card>

            <div className={style.scoreShow}>
                <Card title={'体测成绩'} style={{background: '#fff'}}>
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel key='1' title='第一项'>
                            123
                        </Collapse.Panel>
                        <Collapse.Panel key='2' title='第二项'>
                            123
                        </Collapse.Panel>
                        <Collapse.Panel key='3' title='第三项'>
                            123
                        </Collapse.Panel>
                    </Collapse>
                </Card>
            </div>

        </div>
    </>
}