import {TabBar} from "antd-mobile";
import {MdAccountCircle, MdAppRegistration, MdQrCode} from "react-icons/md";
import {Outlet, useLocation, useNavigate} from "react-router";

export default function StuIndex() {
    const location = useLocation()
    const navigate = useNavigate()
    return <>
        <Outlet/>
        <div style={{
            position: 'fixed',
            bottom: '0',
            width: '100%',
            background: '#F8F9F9',
            padding: '0.2em 0',
            borderWidth: '1px 0 0 0',
            borderColor: '#D1D1D1',
            borderStyle: 'solid'
        }}>
            <TabBar activeKey={location.pathname} onChange={key => {
                navigate(key, {replace: true})
            }}>
                <TabBar.Item title={'个人信息'} key={'/student'} icon={<MdAccountCircle/>}/>
                <TabBar.Item title={'出示二维码'} key={'/student/qrcode'} icon={<MdQrCode/>}/>
                <TabBar.Item title={'查看预约'} key={'/student/appointment'} icon={<MdAppRegistration/>}/>
            </TabBar>
        </div>
    </>
}