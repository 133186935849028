import style from "./Login.module.css"
import {Button, CapsuleTabs, Form, Input} from "antd-mobile";
import {useNavigate} from "react-router";

export default function Login() {
    const navigate = useNavigate()
    return <>
        <div style={{background: '#6CB0F7', height: '100vh'}}>
            <div className={style.box}>
                <CapsuleTabs defaultActiveKey={'stu'}>
                    <CapsuleTabs.Tab title='学生' key='stu'/>
                    <CapsuleTabs.Tab title='工作人员' key='admin'/>
                </CapsuleTabs>

                <Form mode='card'>
                    <Form.Item label='学号'>
                        <Input placeholder='请输入您的学号'/>
                    </Form.Item>
                    <Form.Item label='姓名'>
                        <Input placeholder='请输入您的姓名'/>
                    </Form.Item>
                    <Form.Item label='密码'>
                        <Input placeholder='密码默认是身份证后六位'/>
                    </Form.Item>
                </Form>

                <Button block color='primary' style={{marginTop: '0.5em'}} onClick={() => {
                    navigate('/student')
                }}>登录</Button>
            </div>
            <div style={{color: '#fff', fontSize: 'small', textAlign: 'center'}}>
                Copyright © 雨看就 All Rights Reserved
            </div>
        </div>
    </>
}