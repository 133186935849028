import React from 'react';
import ReactDOM from 'react-dom/client';
import {HashRouter, Navigate, Route, Routes} from "react-router-dom";
import Login from "./page/Login";
import './index.css'
import StuIndex from "./page/Student";
import StuInfo from "./page/Student/info";
import StuQrcode from "./page/Student/qrcode";
import StuAppointment from "./page/Student/appointment";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <div style={{display: 'flex', flexDirection: 'column', fontSize: '1.5em'}}>
            <HashRouter>
                <Routes>
                    <Route index element={<Navigate to={'login'}/>}/>
                    <Route path={'login'} element={<Login/>}/>
                    <Route path={'student'} element={<StuIndex/>}>
                        <Route index element={<StuInfo/>}/>
                        <Route path={'qrcode'} element={<StuQrcode/>}/>
                        <Route path={'appointment'} element={<StuAppointment/>}/>
                    </Route>
                </Routes>
            </HashRouter>
        </div>
    </React.StrictMode>
);
